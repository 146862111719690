/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import Card from "components/card";
import RoleDropdown from "views/admin/user/components/dropdown/RoleDropdown";
import MerchantDropdown from "views/admin/user/components/dropdown/MerchantDropdown";
import SubaccountDropdown from "views/admin/user/components/dropdown/SubaccountDropdown";
import axios from "axios";
import { Button } from "@chakra-ui/react";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import SweetAlert2 from "react-sweetalert2";

interface AddRoleModalProps {
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  userName: string;
  userEmail: string;
  roleOptions: string[];
  merchants: { id: string; name: string }[] | null;
  getSubaccountOptions: (
    merchantId: string
  ) => Promise<{ id: string; suba_name: string }[]>;
  isSetSubaccounts: boolean;
  fetchUserRoles: any;
}

const AddRoleModal: React.FC<AddRoleModalProps> = ({
  showForm,
  setShowForm,
  userName,
  userEmail,
  roleOptions,
  merchants,
  getSubaccountOptions,
  isSetSubaccounts,
  fetchUserRoles
}) => {
  const [newRole, setNewRole] = useState({
    role: "",
    merchant: "",
    subaccount: "",
  });
  const [filteredSubaccounts, setFilteredSubaccounts] = useState<
    { id: string; suba_name: string }[]
  >([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [swalProps, setSwalProps] = useState<any>({});

  const userRole = localStorage.getItem("userRole")?.toLowerCase();
  const sysUsername = localStorage.getItem("username")?.toLowerCase();

  useEffect(() => {
    if (newRole.merchant) {
      const fetchSubaccounts = async () => {
        const fetchedSubaccounts = await getSubaccountOptions(newRole.merchant);
        setFilteredSubaccounts(fetchedSubaccounts);
      };
      fetchSubaccounts();
    } else {
      setFilteredSubaccounts([]);
    }
  }, [newRole.merchant]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setNewRole((prevRole) => ({
      ...prevRole,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setNewRole({
      role: "",
      merchant: "",
      subaccount: "",
    });
  };

  const isFormValid = newRole !== null ? (
    newRole.role
    && newRole.merchant
    && newRole.subaccount
  ) : false;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/add-user-role`,
        {
          email: userEmail,
          username: userName,
          role: newRole.role,
          merchantId: newRole.merchant,
          subaccountId: newRole.subaccount,
          userRole,
          sysUsername,
        }
      );
      setShowForm(false);

      const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider(
        {
          region: process.env.REACT_APP_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        }
      );

      const adminAddUserToGroupParams: CognitoIdentityServiceProvider.Types.AdminAddUserToGroupRequest =
        {
          GroupName: newRole.role,
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Username: userEmail,
        };

      await cognitoIdentityServiceProvider
        .adminAddUserToGroup(adminAddUserToGroupParams)
        .promise();

      setSwalProps({
        show: true,
        icon: "success",
        title: "Success",
        html: "Role successfully added!",
        showConfirmButton: false,
        timer: 2000,
        didClose: () => {
          resetForm();
          setSwalProps({});
          setIsSubmitting(false);
          fetchUserRoles(true);
        },
      });
    } catch (error) {
      console.error("Error adding user role:", error);
      setSwalProps({
        show: true,
        icon: "error",
        title: "Oops!",
        html: "Failed to add role. Please try again.",
        showConfirmButton: true,
        timer: 2000,
        didClose: () => {
          setSwalProps({});
          setIsSubmitting(false);
        },
      });
    }
  };

  return (
    <>
      <SweetAlert2 {...swalProps} />
      <Modal
        isOpen={showForm}
        onClose={() => {
          resetForm();
          setShowForm(false);
        }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
          <ModalBody className="!text-navy-700">
            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
              <h1 className="mb-[20px] text-2xl font-bold">Add Role</h1>
              <form onSubmit={handleSubmit}>
                <div className="mb-[20px]">
                  <label className="mb-1 block text-sm font-medium text-navy-700 dark:text-white">
                    Merchant *
                  </label>
                  <MerchantDropdown
                    newUser={{ merchant: newRole.merchant }}
                    handleInputChange={handleInputChange}
                    merchants={merchants}
                  />
                </div>
                <div className="mb-[20px]">
                  <label className="mb-1 block text-sm font-medium text-navy-700 dark:text-white">
                    Subaccount *
                  </label>
                  <SubaccountDropdown
                    newUser={{ subaccount: newRole.subaccount }}
                    handleInputChange={handleInputChange}
                    merchants={merchants}
                    subaccounts={filteredSubaccounts}
                    isSetSubaccounts={isSetSubaccounts}
                  />
                </div>
                <div className="mb-[20px]">
                  <label className="mb-1 block text-sm font-medium text-navy-700 dark:text-white">
                    Role *
                  </label>
                  <RoleDropdown
                    newUser={newRole}
                    handleInputChange={handleInputChange}
                    roleOptions={roleOptions}
                  />
                </div>
                <div className="flex justify-center gap-2">
                  <Button
                    onClick={() => {
                      resetForm();
                      setShowForm(false);
                    }}
                    isDisabled={isSubmitting}
                    className="btn btn-default"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    isDisabled={!isFormValid}
                    isLoading={isSubmitting}
                    loadingText="Adding..."
                    className="btn btn-primary"
                  >
                    Add
                  </Button>
                </div>
              </form>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddRoleModal;
