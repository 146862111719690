import React, { useState } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button } from '@chakra-ui/react';
import { IoCheckmark } from "react-icons/io5";

const BatchProcessConfirmModal: React.FC<{
    batchToProcessConfirm: any;
    isOpen: boolean;
    onClose(): void;
    triggerReload(): void;
    setSwalProps: any;
}> = ({ isOpen, onClose, triggerReload, batchToProcessConfirm, setSwalProps }) => {

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const userRole = localStorage.getItem("userRole")?.toLowerCase();

    const closeModal = async () => {
        onClose();
    };

    const handleSubmit = async () => {
        try {
            setIsProcessing(true);

            const postData = {
                batch_id: batchToProcessConfirm.id,
                username: localStorage.getItem("username"),
                userRole: userRole,
                subaccount_id: batchToProcessConfirm.subaccount.id
            }

            await axios.post(process.env.REACT_APP_API_URL + `/api/batch/process-provider`, postData)
                .then(function (response) {
                    if (response && response.data?.status === 'ok') {
                        onClose();
                        setSwalProps({
                            show: true,
                            icon: "success",
                            title: "Success",
                            html: "The batch has been processed",
                            showConfirmButton: true,
                            didClose: () => {
                                triggerReload();
                                setSwalProps({});
                                setIsProcessing(false);
                            },
                        });
                    } else {
                        setIsProcessing(false);
                    }
                })
                .catch(function (error) {
                    setIsProcessing(false);
                    setSwalProps({
                        show: true,
                        icon: "error",
                        title: "Oops!",
                        html: error.response.data.message,
                        showConfirmButton: true,
                        didClose: () => {
                            setSwalProps({});
                            setIsProcessing(false);
                        },
                    });
                });
        } catch (error: any) {
            setIsProcessing(false);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Something went wrong, please try again later.",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                },
            });
        }
    };

    const displayAmount = (amount: any) => {
        if (amount === 0) {
            return "$0.00";
        }
        return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <>
            {batchToProcessConfirm &&
                <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                    <ModalOverlay className="bg-[#000] !opacity-30" />
                    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                        <ModalBody className="!text-navy-700">
                            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                                <h1 className="mb-[20px] text-2xl font-bold">Process the batch?</h1>
                                <div className="mb-[20px] text-sm">
                                    <div className="mb-4">
                                        Please confirm if you wish to <strong>process</strong> the current batch. This action cannot be undone.
                                    </div>
                                    {userRole === 'superadmin' && batchToProcessConfirm.provider_name && batchToProcessConfirm.provider_account_name && batchToProcessConfirm.provider_method_name &&
                                        <>
                                            <div className="mb-1">
                                                <span className="mr-1 font-bold">Provider:</span> {batchToProcessConfirm !== null && batchToProcessConfirm.provider_name !== null ? batchToProcessConfirm.provider_name + " (" + batchToProcessConfirm.provider_account_name + ")" : (<>{"-"}</>)}
                                            </div>
                                            <div className="mb-1">
                                                <span className="mr-1 font-bold">Provider Method:</span> {batchToProcessConfirm.provider_method_name}
                                            </div>
                                        </>
                                    }
                                    {userRole !== 'superadmin' &&
                                        <div className="mb-1">
                                            <span className="mr-1 font-bold">Reference:</span> {batchToProcessConfirm.batch_reference}
                                        </div>
                                    }
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Record(s):</span> {batchToProcessConfirm.record}
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Amount:</span> {displayAmount(batchToProcessConfirm.amount)} {batchToProcessConfirm.asset}
                                    </div>
                                </div>
                                <div className="flex gap-2 justify-end">
                                    <Button
                                        onClick={() => closeModal()}
                                        isDisabled={isProcessing}
                                        className="btn btn-default"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        isLoading={isProcessing}
                                        loadingText='Processing...'
                                        className="btn btn-primary">
                                        <IoCheckmark className="h-4 w-4" />&nbsp;Confirm
                                    </Button>
                                </div>
                            </Card>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </>
    );
};

export default BatchProcessConfirmModal;
