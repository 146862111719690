/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Select } from "@chakra-ui/react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import AutoComplete from 'react-select';
import InputField from "components/fields/InputField";

const InputStyle =
  "daterange-picker font-bold text-navy-700 h-10 mt-2 w-full items-center shadow-sm rounded-md border bg-white/0 p-3 text-sm outline-none shadow-sm border-gray-300 dark:!border-white/10 dark:text-white";

const dateRangePropsConfigs = {
  dateNavBtnProps: {
    variant: "outline",
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      borderColor: "rgb(233 236 239 / 1)", // Default border color
      borderRadius: "2px",
      _hover: {
        background: "#4fb06d", // Background color on hover
        color: "white",
      },
    },
    isInRangeBtnProps: {
      background: "#65e68d", // Background color for dates in the selected range
      color: "white", // Text color for dates in the selected range
    },
    selectedBtnProps: {
      background: "#4fb06d", // Background color for selected date
      color: "white", // Text color for selected date
    },
    todayBtnProps: {
      // color: "#422afb", // Background color for today's date
      // fontWeight: "bold",
    },
  },
  inputProps: {
    minWidth: "230px",
    className: InputStyle,
  },
  popoverCompProps: {
    popoverContentProps: {
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)", // Apply custom box shadow
    },
  },
  calendarPanelProps: {
    wrapperProps: {
      borderColor: "#81E6D9", // Border color for the calendar wrapper
    },
    contentProps: {
      borderWidth: 0,
    },
    headerProps: {
      padding: "5px",
    },
    dividerProps: {
      display: "none",
    },
  },
  weekdayLabelProps: {
    fontWeight: "bold",
    fontSize: 16,
    color: "rgb(26 36 74)"
  },
  dateHeadingProps: {
    fontWeight: "bold",
    fontSize: 16,
    color: "rgb(26 36 74)"
  },
};

const PayoutSearch: React.FC<{
  userRole: any;
  isFilterLoading: boolean;
  merchantList?: any | null;
  subaccountList?: any | null;
  receiverList: any;
  receiverAccountList: any;
  filters: any;
  setFilters: any;
  setSelectedDates: any;
  selectedDates: any;
  isSubaccountListLoading?: boolean;
  isReceiverListLoading?: boolean;
  isReceiverAccountListLoading: boolean;
  setMerchantIdToGetSubaccount?: any | null;
  setSubaccountIdToGetReceiver?: any | null;
  setReceiverIdToReceiverAccount: any;
  handleSearchSubmit: () => void;
  handleSearchClear: () => void;
}> = ({ 
  userRole, 
  isFilterLoading, 
  merchantList, 
  subaccountList, 
  receiverList, 
  receiverAccountList, 
  filters, 
  setFilters, 
  setSelectedDates, 
  selectedDates, 
  isSubaccountListLoading, 
  isReceiverListLoading, 
  isReceiverAccountListLoading, 
  setMerchantIdToGetSubaccount, 
  setSubaccountIdToGetReceiver, 
  setReceiverIdToReceiverAccount, 
  handleSearchSubmit, 
  handleSearchClear
}) => {
  const [merchantAutoCompleteOptions, setMerchantAutoCompleteOptions] = useState<any>(null);
  const [subaccountAutoCompleteOptions, setSubaccountAutoCompleteOptions] = useState<any>(null);
  const [receiverAutoCompleteOptions, setReceiverAutoCompleteOptions] = useState<any>(null);
  const [receiverAccountAutoCompleteOptions, setReceiverAccountAutoCompleteOptions] = useState<any>(null);
  
  const merchantAutoCompleteRef = useRef(null);
  const subaccountAutoCompleteRef = useRef(null);
  const receiverAutoCompleteRef = useRef(null);
  const receiverAccountAutoCompleteRef = useRef(null);  

  useEffect(() => {
    if (merchantList !== null) {
      let rebuildListArray: any = [];
      if (merchantList.length > 0) {
        merchantList.forEach((i: any) => {
          let newObj = {
            value: i.id,
            label: i.name
          }
          rebuildListArray.push(newObj);
        });
      }

      setMerchantAutoCompleteOptions(rebuildListArray);
    }
  }, [merchantList]);

  useEffect(() => {
    if (subaccountList !== null) {
      let rebuildListArray: any = [];
      if (subaccountList.length > 0) {
        subaccountList.forEach((i: any) => {
          let newObj = {
            value: i.id,
            label: i.suba_name
          }
          rebuildListArray.push(newObj);
        });
      }

      setSubaccountAutoCompleteOptions(rebuildListArray);
    }
  }, [subaccountList]);

  useEffect(() => {
    if (receiverList !== null) {
      let rebuildListArray: any = [];
      if (receiverList.length > 0) {
        receiverList.forEach((i: any) => {
          let newObj = {
            value: i.id,
            label: `${i.first_name} ${i.last_name} (${i.email})`
          }
          rebuildListArray.push(newObj);
        });
      }

      setReceiverAutoCompleteOptions(rebuildListArray);
    }
  }, [receiverList]);

  useEffect(() => {
    if (receiverAccountList !== null) {
      let rebuildListArray: any = [];
      if (receiverList.length > 0) {
        receiverAccountList.forEach((i: any) => {
          let newObj = {
            value: i.id,
            label: `${i.bank_account_data.bank_code} / ${i.bank_account_data.bsb} / ${i.bank_account_data.account_number}`
          }
          rebuildListArray.push(newObj);
        });
      }

      setReceiverAccountAutoCompleteOptions(rebuildListArray);
    }
  }, [receiverAccountList]);

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setFilters({ ...filters, [id]: value });
  };

  const handleMerchantAutoCompleteChange = (item: any) => {
    setSubaccountAutoCompleteOptions(null);
    setReceiverAutoCompleteOptions(null);
    setReceiverAccountAutoCompleteOptions(null);

    subaccountAutoCompleteRef.current?.clearValue();
    receiverAutoCompleteRef.current?.clearValue();
    receiverAccountAutoCompleteRef.current?.clearValue();

    localStorage.setItem("merchantIdToFilterPayout", "");
    if (item !== null) {
      localStorage.setItem("merchantIdToFilterPayout", item.value);
      setFilters({ ...filters, subaccount_id: "" });
      setMerchantIdToGetSubaccount(item.value);
    } else {
      setFilters({ ...filters, subaccount_id: "" });
      setMerchantIdToGetSubaccount(null);
    }
  };

  const noMerchantOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
    return <div className="text-sm">Merchant not found for "{inputValue}"</div>;
  };

  const handleSubaccountAutoCompleteChange = (item: any) => {
    setReceiverAutoCompleteOptions(null);
    setReceiverAccountAutoCompleteOptions(null);

    receiverAutoCompleteRef.current?.clearValue();
    receiverAccountAutoCompleteRef.current?.clearValue();

    localStorage.setItem("subaccountIdToFilterPayout", "");
    if (item !== null) {
      localStorage.setItem("subaccountIdToFilterPayout", item.value);
      setFilters({ ...filters, subaccount_id: "" });
      setSubaccountIdToGetReceiver(item.value);
    } else {
      setFilters({ ...filters, subaccount_id: "" });
      setSubaccountIdToGetReceiver(null);
    }
  };

  const noSubaccountOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
    return <div className="text-sm">Subaccount not found for "{inputValue}"</div>;
  };

  const handleReceiverAutoCompleteChange = (item: any) => {
    localStorage.setItem("receiverIdToFilterPayout", "");
    receiverAccountAutoCompleteRef.current?.clearValue();
    if (item !== null) {
      localStorage.setItem("receiverIdToFilterPayout", item.value);
      setFilters({ ...filters, receiver_account_id: "" });
      setReceiverIdToReceiverAccount(item.value);
    } else {
      setFilters({ ...filters, receiver_account_id: "" });
      setReceiverIdToReceiverAccount(null);
    }
  };

  const noReceiverOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
    return <div className="text-sm">Receiver not found for "{inputValue}"</div>;
  };

  const handleReceiverAccountAutoCompleteChange = (item: any) => {
    localStorage.setItem("receiverAccountIdToFilterPayout", "");
    if (item !== null) {
      localStorage.setItem("receiverAccountIdToFilterPayout", item.value);
    }
  };

  const noReceiverAccountOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
    return <div className="text-sm">Receiver not found for "{inputValue}"</div>;
  };

  const handleClear = () => {
    merchantAutoCompleteRef.current?.clearValue();
    subaccountAutoCompleteRef.current?.clearValue();
    receiverAutoCompleteRef.current?.clearValue();
    receiverAccountAutoCompleteRef.current?.clearValue();

    if(userRole === 'superadmin') {  
      setSubaccountAutoCompleteOptions(null);
      setReceiverAutoCompleteOptions(null);
      setReceiverAccountAutoCompleteOptions(null);
    } else {
      setReceiverAccountAutoCompleteOptions(null);
    }

    handleSearchClear();
  };

  return (
    <Box
      className="mt-5 mb-3 flex flex-wrap items-center card card-pad"
    >
      <div className="flex w-full">
        {userRole === 'superadmin' &&
          <>
            <div className="w-1/4 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Merchant
              </label>
              <AutoComplete
                ref={merchantAutoCompleteRef}
                isDisabled={isFilterLoading || merchantAutoCompleteOptions === null}
                placeholder={merchantAutoCompleteOptions === null ? 'Retrieving...' : 'All'}
                options={merchantAutoCompleteOptions}
                classNamePrefix="search-payout-receiver"
                className="autocomplete-override mt-2"
                onChange={(item: any) => handleMerchantAutoCompleteChange(item)}
                noOptionsMessage={noMerchantOptionsMessageRenderer}
              />
            </div>
            <div className="w-1/4 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Subaccount
              </label>
              <AutoComplete
                ref={subaccountAutoCompleteRef}
                classNamePrefix="search-payout-receiver"
                className="autocomplete-override mt-2"
                isDisabled={isFilterLoading || isSubaccountListLoading || subaccountAutoCompleteOptions === null || subaccountAutoCompleteOptions.length === 0}
                placeholder={
                  isSubaccountListLoading
                    ? 'Retrieving...'
                    : subaccountAutoCompleteOptions === null
                    ? 'All'
                    : subaccountAutoCompleteOptions.length === 0
                    ? 'N/A'
                    : 'All'
                }
                options={subaccountAutoCompleteOptions}
                onChange={(item: any) => handleSubaccountAutoCompleteChange(item)}
                noOptionsMessage={noSubaccountOptionsMessageRenderer}
              />
            </div>
            <div className="flex-1 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Receiver
              </label>
              <AutoComplete
                ref={receiverAutoCompleteRef}
                classNamePrefix="search-payout-receiver"
                className="autocomplete-override mt-2"
                isDisabled={isFilterLoading || isReceiverListLoading || receiverAutoCompleteOptions === null || receiverAutoCompleteOptions.length === 0}
                placeholder={
                  isReceiverListLoading
                    ? 'Retrieving...'
                    : receiverAutoCompleteOptions === null
                    ? 'All'
                    : receiverAutoCompleteOptions.length === 0
                    ? 'N/A'
                    : 'All'
                }
                options={receiverAutoCompleteOptions}
                onChange={(item: any) => handleReceiverAutoCompleteChange(item)}
                noOptionsMessage={noReceiverOptionsMessageRenderer}
              />
            </div>
            <div className="flex-1 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Receiver Account
              </label>
              <AutoComplete
                ref={receiverAccountAutoCompleteRef}
                classNamePrefix="search-payout-receiver"
                className="autocomplete-override mt-2"
                isDisabled={isFilterLoading || isReceiverAccountListLoading || receiverAccountAutoCompleteOptions === null || receiverAccountAutoCompleteOptions.length === 0}
                placeholder={
                  isReceiverAccountListLoading
                    ? 'Retrieving...'
                    : receiverAccountAutoCompleteOptions === null
                    ? 'All'
                    : receiverAccountAutoCompleteOptions.length === 0
                    ? 'N/A'
                    : 'All'
                }
                options={receiverAccountAutoCompleteOptions}
                onChange={(item: any) => handleReceiverAccountAutoCompleteChange(item)}
                noOptionsMessage={noReceiverAccountOptionsMessageRenderer}
              />
            </div>
          </>
        }
        {userRole !== 'superadmin' &&
          <>
            <div className="flex-1 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Date
              </label>
              <div className="date-picker-custom">
                <RangeDatepicker
                  disabled={isFilterLoading}
                  selectedDates={selectedDates}
                  onDateChange={setSelectedDates}
                  propsConfigs={dateRangePropsConfigs}
                  configs={{
                    dateFormat: 'MMM d, yyyy'
                  }}
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="flex-1 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Receiver
              </label>
              <AutoComplete
                ref={receiverAutoCompleteRef}
                classNamePrefix="search-payout-receiver"
                className="autocomplete-override mt-2"
                isDisabled={isFilterLoading || isReceiverListLoading || receiverAutoCompleteOptions === null}
                placeholder={
                  isReceiverListLoading
                    ? 'Retrieving...'
                    : receiverAccountAutoCompleteOptions === null
                      ? 'All'
                      : receiverAccountAutoCompleteOptions.length === 0
                        ? 'N/A'
                        : 'All'
                }
                options={receiverAutoCompleteOptions}
                onChange={(item: any) => handleReceiverAutoCompleteChange(item)}
                noOptionsMessage={noReceiverOptionsMessageRenderer}
              />
            </div>
            <div className="flex-1 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Receiver Account
              </label>
              <AutoComplete
                ref={receiverAccountAutoCompleteRef}
                classNamePrefix="search-payout-receiver"
                className="autocomplete-override mt-2"
                isDisabled={isFilterLoading || isReceiverAccountListLoading || receiverAccountAutoCompleteOptions === null || receiverAccountAutoCompleteOptions.length === 0}
                placeholder={
                  isReceiverAccountListLoading
                    ? 'Retrieving...'
                    : receiverAccountAutoCompleteOptions === null
                      ? 'All'
                      : receiverAccountAutoCompleteOptions.length === 0
                        ? 'N/A'
                        : 'All'
                }
                options={receiverAccountAutoCompleteOptions}
                onChange={(item: any) => handleReceiverAccountAutoCompleteChange(item)}
                noOptionsMessage={noReceiverAccountOptionsMessageRenderer}
              />
            </div>
            <div className="flex-1 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Asset
              </label>
              <Select isDisabled={isFilterLoading} id="asset" value={filters.asset} onChange={handleInputChange} className="h-10  mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
              <option value="">All</option>
              <option value="AUD">AUD</option>
              <option value="CAD">CAD</option>
              </Select>
            </div>
          </>
        }
      </div>
      <div className="flex w-full">
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Field
          </label>
          <Select isDisabled={isFilterLoading} id="field" value={filters.field} onChange={handleInputChange} className="text-navy-700 mt-2 font-bold text-sm h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full shadow-sm">
            <option value="">Select Field</option>
            <option value="id">ID</option>
            <option value="reference_tracking">Ref. Tracking</option>
          </Select>
        </div>
        <div className="w-1/2 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Keyword
          </label>
          <InputField
            disabled={isFilterLoading}
            variant="auth"
            extra="shadow-sm rounded-md"
            label=""
            placeholder=""
            id="keyword"
            type="text"
            autoComplete="off"
            value={filters.keyword}
            onChange={handleInputChange}
          />
        </div>
        {userRole === 'superadmin' &&
          <>
            <div className="w-1/4 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Date
              </label>
              <RangeDatepicker
                disabled={isFilterLoading}
                selectedDates={selectedDates}
                onDateChange={setSelectedDates}
                propsConfigs={dateRangePropsConfigs}
                configs={{
                  dateFormat: 'MMM d, yyyy'
                }}
                maxDate={new Date()}
              />
            </div>
            <div className="w-1/6 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Asset
              </label>
              <Select isDisabled={isFilterLoading} id="asset" value={filters.asset} onChange={handleInputChange} className="h-10  mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
                <option value="">All</option>
                <option value="AUD">AUD</option>
                <option value="CAD">CAD</option>
              </Select>
            </div>
          </>
        }
        <div className="w-1/3 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Status
          </label>
          <Select isDisabled={isFilterLoading} id="status" value={filters.status} onChange={handleInputChange} className="h-10  mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
            <option value="">All</option>
            <option value="1">Pending</option>
            <option value="2">Unassessed</option>
            <option value="3">In Progress</option>
            <option value="4">Successful</option>
            <option value="5">Cancelled</option>
            <option value="6">Failed</option>
            <option value="7">Successful Pending KYC</option>
          </Select>
        </div>
        <div className="flex-1 p-2 pt-0 flex justify-end items-end">
          <Button
            isDisabled={isFilterLoading || isSubaccountListLoading || isReceiverListLoading || isReceiverAccountListLoading}
            onClick={() => handleClear()}
            className="mr-3 rounded-md bg-gray-100 px-3 py-2.5 text-sm font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
          >
            Clear Search
          </Button>
          <Button
            isDisabled={isFilterLoading || isSubaccountListLoading || isReceiverListLoading || isReceiverAccountListLoading}
            onClick={() => handleSearchSubmit()}
            className="rounded-md px-3 py-2.5 text-sm transition duration-200 btn-primary"
          >
            Seach Payouts
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default PayoutSearch;
