import React from "react";
import { Spinner } from "@chakra-ui/spinner";

const SubaccountDropdown = ({
  newUser,
  handleInputChange,
  merchants,
  subaccounts,
  isSetSubaccounts
}: any) => {
  const handleOptionClick = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleInputChange({
      target: { name: "subaccount", value: event.target.value },
    });
  };

  return (
    <div>
      <select
        id="subaccount"
        defaultValue={newUser.subaccount}
        onChange={handleOptionClick}
        className="text-navy-700 mt-2 font-bold text-sm h-10 rounded-md  custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full shadow-sm"
        disabled={isSetSubaccounts || subaccounts === null || subaccounts.length === 0}
      >
        {isSetSubaccounts || !merchants ? (
          <option>Retreiving...</option>
        ) : subaccounts.length === 0 ? (
          <option>-- Please Select Merchant --</option>
        ) : (
          <>
            {subaccounts.map((subaccount: any) => (
              <option key={subaccount.id} value={subaccount.id}>
                {subaccount.suba_name}
              </option>
            ))}
          </>
        )}
      </select>
      {!subaccounts && (
        <div className="mt-2 flex justify-center">
          <Spinner size="sm" />
        </div>
      )}
    </div>
  );
};

export default SubaccountDropdown;
