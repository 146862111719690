/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, FormEvent, useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import Card from "components/card";
import InputField from "components/fields/InputField";
import RoleDropdown from "views/admin/user/components/dropdown/RoleDropdown";
import MerchantDropdown from "views/admin/user/components/dropdown/MerchantDropdown";
import SubaccountDropdown from "views/admin/user/components/dropdown/SubaccountDropdown";
import { Button } from "@chakra-ui/react";

interface Props {
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  newUser: {
    username: any;
    email: string;
    role: string;
    merchant: string;
    subaccount: string;
  };
  handleInputChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  roleOptions: string[];
  merchants: { id: string; name: string }[] | null;
  subaccounts: { id: string; suba_name: string }[] | null;
  resetNewUser: () => void;
  isSubmitting: boolean;
  isSetSubaccounts: boolean;
  setSubaccounts: any;
}

const CreateUserModal: React.FC<Props> = ({
  showForm,
  setShowForm,
  handleSubmit,
  newUser,
  handleInputChange,
  roleOptions,
  merchants,
  subaccounts,
  resetNewUser,
  isSubmitting,
  isSetSubaccounts,
  setSubaccounts,
}) => {
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [merchantError, setMerchantError] = useState(false);
  const [subaccountError, setSubaccountError] = useState(false);

  const handleSubmitWithValidation = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setUsernameError(!newUser.username);
    setEmailError(!newUser.email);
    setRoleError(!newUser.role);
    setMerchantError(!newUser.merchant);
    setSubaccountError(!newUser.subaccount);

    if (
      newUser.username &&
      newUser.email &&
      newUser.role &&
      newUser.merchant &&
      newUser.subaccount
    ) {
      handleSubmit(e);
    }
  };

  const handleModalClose = () => {
    setSubaccounts([]);
    setUsernameError(false);
    setEmailError(false);
    setRoleError(false);
    setMerchantError(false);
    setSubaccountError(false);
    resetNewUser();
    setShowForm(false);
  };

  return (
    <Modal isOpen={showForm} onClose={handleModalClose} closeOnOverlayClick={false}>
      <ModalOverlay className="bg-[#000] !opacity-30" />
      <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
        <ModalBody className="!text-navy-700">
          <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
            <h1 className="mb-[20px] text-2xl font-bold">Create New User</h1>
            <form onSubmit={handleSubmitWithValidation}>

              <div className="mb-[20px]">
                <label className="mb-1 block text-sm font-medium text-navy-700 dark:text-white">
                  Role *
                </label>
                <RoleDropdown
                  newUser={newUser}
                  handleInputChange={handleInputChange}
                  roleOptions={roleOptions}
                />
              </div>

              <div className="mb-[20px]">
                <label className="mb-1 block text-sm font-medium text-navy-700 dark:text-white">
                  Merchant *
                </label>
                <MerchantDropdown
                  newUser={{ merchant: newUser.merchant }}
                  handleInputChange={handleInputChange}
                  merchants={merchants}
                />
              </div>

              <div className="mb-[20px]">
                <label className="mb-1 block text-sm font-medium text-navy-700 dark:text-white">
                  Subaccount *
                </label>
                <SubaccountDropdown
                  newUser={{ subaccount: newUser.subaccount }}
                  handleInputChange={handleInputChange}
                  merchants={merchants}
                  subaccounts={subaccounts}
                  isSetSubaccounts={isSetSubaccounts}
                />
              </div>

              <div className="mb-[20px]">
                <InputField
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="Username *"
                  placeholder=""
                  id="username"
                  type="text"
                  name="username"
                  autoComplete="off"
                  value={newUser.username}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-[20px]">
                <InputField
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="Email *"
                  placeholder=""
                  id="email"
                  type="email"
                  name="email"
                  autoComplete="off"
                  value={newUser.email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex justify-end gap-2">
                <Button
                  onClick={handleModalClose}
                  isDisabled={isSubmitting}
                  className="btn btn-default"
                >
                  Cancel
                </Button>
                <Button
                  isDisabled={
                    newUser.username &&
                      newUser.email &&
                      newUser.role &&
                      newUser.merchant &&
                      newUser.subaccount ? false : true || isSubmitting
                  }
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Creating User..."
                  className="btn btn-primary"
                >
                  Create
                </Button>
              </div>
            </form>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateUserModal;
