import React, { ReactElement, useState } from "react";
import Box from "@mui/material/Box";
import {  Badge } from '@chakra-ui/react'
import { DataGrid, GridColDef, GridRenderCellParams, GridClasses } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { AiOutlineFileSearch } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import BatchCancelConfirmModal from "./BatchCancelConfirmModal";
import SweetAlert2 from "react-sweetalert2";
import axios from "axios";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

type Merchant = {
  id: string;
  name: string;
};

type Subaccount = {
  id: string;
  name: string;
};

type RowData = {
  id: string;
  file_name: string;
  file_bucket: string;
  file_key: string;
  process_date: string;
  merchant: Merchant;
  subaccount: Subaccount;
  records: string;
  amount: string;
  created_at: string;
  status: string;
  provider_name: string;
  provider_account_name: string;
  method_name: string;
  asset: string;
};

const BatchList: React.FC<{
  isLoading: boolean;
  tableData: RowData[];
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
  currentLastKey: string;
  triggerReloadBatchList(): void;
}> = ({
  isLoading,
  tableData,
  page,
  pageSize,
  rowCount,
  setPaginationModel,
  currentLastKey,
  triggerReloadBatchList
}) => {

    const [batchToCancel, setBatchToCancel] = useState<any>(null);
    const [isOpenBatchCancelConfirmModal, setIsOpenBatchCancelConfirmModal] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [swalProps, setSwalProps] = useState<any>({});
    const navigate = useNavigate();
    const userRole = localStorage.getItem("userRole")?.toLowerCase();

    const columns: GridColDef[] = [
      {
        field: "created_at",
        headerName: "Created Date",        
        minWidth: 130,
        sortable: false,
        renderCell: (params: GridRenderCellParams<RowData, string>) =>
          formatDate(params.row.created_at),
      },
      {
        field: "process_date",
        headerName: "Process Date",
        sortable: false,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<RowData, string>) =>
          formatDate(params.row.process_date, true),
      },
      {
        field: "subaccount",
        headerName: "Subaccount",
        sortable: false,
        minWidth: 160,
        renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
          params.row.subaccount.name,
      },
      {
        field: "file_name", headerName: "File", sortable: false, 
        minWidth: 160,
        renderCell: (params) => {
          return (
            <>
              {!params?.row?.file_bucket && !params?.row?.file_key && !params?.row?.file_name && (
                ""
              )}
              {params?.row?.file_bucket && params?.row?.file_key && params?.row?.file_name && (
                <span
                  onClick={() => handleClickDownload(params?.row?.file_bucket, params?.row?.file_key, params?.row?.file_name)}
                  className={`${isDownloading ? 'hover:no-underline' : 'cursor-pointer underline'}`}
                >
                  {params.row.file_name}
                </span>
              )}
            </>
          );
        },
      },
      {
        field: "provider_name",
        headerName: "Provider",
        sortable: false,
        minWidth: 200,
        renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.provider_name && params.row.provider_account_name ? params.row.provider_name + " (" + params.row.provider_account_name + ")" : "",
      },
      {
        field: "method_name",
        headerName: "Method",
        sortable: false,
        minWidth: 140,
        renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
          params.row.method_name ? params.row.method_name : "",
      },
      { field: "batch_reference", headerName: "Reference", sortable: false, minWidth: userRole === 'superadmin' ? 200 : 280, },
      { 
        field: "record", 
        headerName: "Record(s)",
        minWidth: userRole === 'superadmin' ? 80 : 140,
        sortable: false, 
        headerAlign: 'center', 
        align: 'center', 
      },
      {
        field: "amount", headerName: "Amount",
        minWidth: userRole === 'superadmin' ? 80 : 140,
        sortable: false, 
        headerAlign: 'right', 
        align: 'right',
        renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
          displayAmount(params.row.amount)
      },
      {
        field: "asset", headerName: "Asset",
        minWidth: 60, sortable: false, headerAlign: 'center', align: 'center',
      },
      {
        field: "status", 
        headerName: "", 
        minWidth: 160,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams<RowData, any, string>) => {
          const status = params.value as string;
          const statusItem = statusList.find(item => item.status === status);
          return statusItem ? statusItem.display : null;
        }
      },
      {
        field: "actions", 
        headerName: "", 
        sortable: false, 
        flex: userRole !== 'superadmin' ? 1 : undefined,
        minWidth: userRole === 'superadmin' ? 100 : undefined,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => {
          return (
            <>
              {((params?.row?.status === 'open') || ((params?.row?.status === 'queue' || params?.row?.status === 'close') && userRole === 'superadmin')) && (
                <Tooltip title="Cancel" arrow placement="top">
                  <IconButton
                    id={"button-cancel-" + params?.row?.id}
                    disabled={isDownloading}
                    onClick={() => handleClickCancel(params.row.id)}
                  >
                    <MdOutlineCancel className="h-5 w-5 text-red-500" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="View More Details" arrow placement="top">
                <IconButton
                  id={"button-detail-" + params?.row?.id}
                  disabled={isDownloading}
                  onClick={() => handleClickDetail(params.row.id)}
                >
                  <AiOutlineFileSearch className="h-5 w-5 text-navy-700" />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    ];

    interface BatchStatusItems {
      status: string;
      value: string;
      display: ReactElement;
    }

    const statusClasses = 'status';

    const statusList: BatchStatusItems[] = [
      { status: "open", value: 'open', display: <Badge className={`${statusClasses} status-open`}>Open</Badge> },
      { status: "close", value: 'close', display: <Badge className={`${statusClasses} status-closed`}>Closed</Badge> },
      { status: "queue", value: 'queue', display: <Badge className={`${statusClasses} status-queued`}>Queued</Badge> },
      { status: "pending", value: 'pending', display: <Badge className={`${statusClasses} status-pending`}>Pending</Badge> },
      { status: "in_progress", value: 'in_progress', display: <Badge className={`${statusClasses} status-in_progress`}>In Progress</Badge> },
      { status: "settled", value: 'settled', display: <Badge className={`${statusClasses} status-settled`}>Settled</Badge> },
      { status: "settled_with_error", value: 'settled_with_error', display: <Badge className={`${statusClasses} status-settled_with_error`}>Settled with Errors</Badge> },
      { status: "cancelled", value: 'cancelled', display: <Badge className={`${statusClasses} status-cancelled`}>Cancelled</Badge> },
      { status: "failed", value: 'failed', display: <Badge className={`${statusClasses} status-failed`}>Failed</Badge> },
    ];

    const formatDate = (timestamp: string, time: boolean = false) => {
      if(timestamp && timestamp !== "") {
        if(time) {
          return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY - hh:mm A");
        } else {
          return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY");
        }        
      }
      return "";
    };

    const displayAmount = (amount: any) => {
        if (amount === 0) {
            return "$0.00";
        }
        return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const customDataGridStyles: Partial<GridClasses> = {
      root: 'mui-table-custom',
      columnHeader: 'text-start text-md',
      row: ''
    };

    const handleClickDetail = (id: any) => {
      navigate(`/${userRole}/batch/${id}`);
    };

    const handleClickCancel = (id: any) => {
      const batch = tableData.filter((x: any) => x.id === id)[0];
      setBatchToCancel(batch);
      showBatchCancelConfirmModal();
    };

    const showBatchCancelConfirmModal = () => {
      setIsOpenBatchCancelConfirmModal(true);
    }

    const closeBatchCancelConfirmModal = () => {
      setBatchToCancel(null);
      setIsOpenBatchCancelConfirmModal(false);
    }

    const filterColumns = () => {
      let rebuildColumns = [];
      if (userRole !== "superadmin") {
        const excludeFields = ["subaccount", "file_name", "provider_name", "method_name"];
        rebuildColumns = columns.filter((column) => !!!excludeFields.includes(column?.field));
      } else {
        rebuildColumns = columns;
      }
      return rebuildColumns;
    };

    const handleClickDownload = async (file_bucket: string, file_key: string, file_name: string) => {
      try {
        setIsDownloading(true);

        const postData = {
          file_bucket: file_bucket,
          file_key: file_key
        }

        await axios.post(
          process.env.REACT_APP_API_URL + `/api/batch/generate-download-url`,
          postData
        )
          .then(async function (response) {
            if (response && response.data?.status === 'ok') {
              const fileUrl = response.data.url;
              const fileResponse = await fetch(fileUrl);
              const blob = await fileResponse.blob();
              
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = file_name;
              link.click();

              window.URL.revokeObjectURL(link.href);
              setIsDownloading(false);
            } else {
              setIsDownloading(false);
              setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: response.data.message,
                showConfirmButton: true,
                didClose: () => {
                  setSwalProps({});
                  setIsDownloading(false);
                },
              });
            }
          })
          .catch(function (error) {
            setIsDownloading(false);
            setSwalProps({
              show: true,
              icon: "error",
              title: "Oops!",
              html: error.response.data.message,
              showConfirmButton: true,
              didClose: () => {
                setSwalProps({});
                setIsDownloading(false);
              },
            });
          });
      } catch (error) {
        setIsDownloading(false);
        setSwalProps({
          show: true,
          icon: "error",
          title: "Oops!",
          html: "Something went wrong, please try again later.",
          showConfirmButton: true,
          didClose: () => {
            setSwalProps({});
            setIsDownloading(false);
          },
        });
      }
    };

    return (
      <>
        <BatchCancelConfirmModal setSwalProps={setSwalProps} triggerReload={triggerReloadBatchList} isOpen={isOpenBatchCancelConfirmModal} batchToCancel={batchToCancel} onClose={() => closeBatchCancelConfirmModal()} />
        
        <div className="flex flex-wrap mt-6 pb-4 pl-3">
          <h3 className="text-lg text-navy-700 font-bold dark:text-white">
            Batches 
          </h3>
        </div>
        <Box
          className="w-full card"
        >
            <DataGrid
              sx={{ 
                '& .MuiDataGrid-cell': {
                  padding: '8px 16px',
                },
                '& .MuiDataGrid-columnHeader': {
                  padding: '8px 16px',
                },
                "& .MuiTablePagination-input": {
                  marginRight: "-20px"
                },
                "& .MuiTablePagination-displayedRows": {
                  display: "none"
                },
              }} 
              loading={isLoading}
              rows={tableData || []}
              columns={filterColumns()}
              getRowId={(row) => row.id}
              rowCount={rowCount}
              sortingMode="server"
              paginationMode="server"
              pageSizeOptions={[10, 25, 50, 100]}
              rowHeight={48}
              columnHeaderHeight={48}
              paginationModel={{ page: page, pageSize: pageSize }}
              onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              classes={customDataGridStyles}
              hideFooter={currentLastKey === "" && page === 0}
              autoHeight
            />
        </Box>
        <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>
        </SweetAlert2>
      </>

    );
  };

export default BatchList;
