/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button } from '@chakra-ui/react';
import { IoCheckmark } from "react-icons/io5";

const UserResetTwoFactorConfirmModal: React.FC<{
    setSwalProps: any;
    triggerReload(): void;
    isOpen: boolean;
    onClose(): void;
    userToUpdate: any;
}> = ({ setSwalProps, triggerReload, isOpen, onClose, userToUpdate }) => {

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const userRole = localStorage.getItem("userRole")?.toLowerCase();
    const sysUsername = localStorage.getItem("username")?.toLowerCase();

    const closeModal = async () => {
        onClose();
    };

    const handleSubmit = async () => {
        setIsProcessing(true);
        try {
            await axios.put(
                `${process.env.REACT_APP_API_URL}/api/user/update-user`,
                {
                    userRole: userRole,
                    sysUsername: sysUsername,
                    subaccountId: null,
                    username: userToUpdate.username,
                    fn: "reset-two-factor"
                }
            );

            onClose();
            setSwalProps({
                show: true,
                icon: "success",
                title: "Success",
                html: "User two-factor authentication has been reset",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                    triggerReload();
                },
            });
        } catch (error: any) {
            console.error("Error proceeding:", error.response.data);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Two-factor reset failed. Please try again or contact support",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                },
            });
        }
    };

    return (
        <>
            {userToUpdate &&
                <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                    <ModalOverlay className="bg-[#000] !opacity-30" />
                    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                        <ModalBody className="!text-navy-700">
                            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                                <h1 className="mb-[20px] text-2xl font-bold">Are you sure you want to proceed this action?</h1>
                                <div className="mb-[20px]">
                                    <div className="mb-8">
                                        Please confirm if you wish to reset the <strong>Two-Factor Authentication</strong> to the user.
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Email:</span> {userToUpdate.email}
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Username:</span> {userToUpdate.username}
                                    </div>
                                </div>
                                <div className="flex gap-2 justify-end">
                                    <Button
                                        onClick={() => closeModal()}
                                        isDisabled={isProcessing}
                                        className="btn btn-default"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        isLoading={isProcessing}
                                        loadingText='Processing...'
                                        className="btn btn-primary">
                                        <IoCheckmark className="h-4 w-4" />&nbsp;Confirm
                                    </Button>
                                </div>
                            </Card>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </>
    );
};

export default UserResetTwoFactorConfirmModal;
