import axios from "axios";
import React, { ChangeEvent, FormEvent, useState, useCallback, useMemo } from "react";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import Card from "components/card";
import RoleDropdown from "views/admin/user/components/dropdown/RoleDropdown";
import SweetAlert2 from "react-sweetalert2";
import { Button } from "@chakra-ui/react";
import { CognitoIdentityServiceProvider } from "aws-sdk";

interface Role {
  role: string;
  merchantId: string;
  subaccountId: string;
}
interface EditRoleModalProps {
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRole: any;
  setUserRoles: any;
  roleOptions: string[];
  username: string;
  email: string;
  editUserChannel: BroadcastChannel;
  fetchUserRoles: any;
  userRoles: Role[];
}

const EditRoleModal: React.FC<EditRoleModalProps> = ({
  showForm,
  setShowForm,
  selectedRole,
  setUserRoles,
  roleOptions,
  username,
  email,
  editUserChannel,
  fetchUserRoles,
  userRoles
}) => {
  const userRole = localStorage.getItem("userRole")?.toLowerCase();
  const sysUsername = localStorage.getItem("username")?.toLowerCase();
  const [editedRole, setEditedRole] = useState(selectedRole?.role || "");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [swalProps, setSwalProps] = useState<any>({});

  const cognitoIdentityServiceProvider = useMemo(
    () =>
      new CognitoIdentityServiceProvider({
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      }),
    []
  );

  const handleRoleChange = useCallback(
    async (role: string, newRole: string, username: string, isHandleRoleRemove: boolean) => {
      if (isHandleRoleRemove) {
        await cognitoIdentityServiceProvider
        .adminRemoveUserFromGroup({
          GroupName: role,
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Username: username,
        })
        .promise();
      }

      await cognitoIdentityServiceProvider
        .adminAddUserToGroup({
          GroupName: newRole,
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Username: username,
        })
        .promise();
    },
    [cognitoIdentityServiceProvider]
  );

  const handleInputChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setEditedRole(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/user/update-user-role?email=${email}`,
        {
          role: editedRole,
          username,
          merchantId: selectedRole?.merchantId,
          subaccountId: selectedRole?.subaccountId,
          userRole,
          sysUsername,
        }
      );

      const selectedRoles: Role[] = userRoles.filter(roleObj => roleObj.role === selectedRole.role);
      const selectedRolesCount: number = selectedRoles.length;
      const isHandleRoleRemove = selectedRolesCount === 1 ? true : false;

      await handleRoleChange(selectedRole?.role, editedRole, username, isHandleRoleRemove);

      setShowForm(false);

      setSwalProps({
        show: true,
        icon: "success",
        title: "Success",
        html: "Role successfully updated!",
        showConfirmButton: false,
        timer: 2000,
        didClose: () => {
          setSwalProps({});
          setIsSubmitting(false);
          fetchUserRoles(true);
        },
      });
    } catch (error) {
      console.error("Error updating user role:", error);
      setSwalProps({
        show: true,
        icon: "error",
        title: "Oops!",
        html: "Failed to change role. Please try again.",
        showConfirmButton: true,
        timer: 2000,
        didClose: () => {
          setSwalProps({});
          setIsSubmitting(false);
        },
      });
    }
  };
  const isSubmitDisabled = selectedRole?.role === editedRole;

  return (
    <>
      <SweetAlert2 {...swalProps} />
      <Modal isOpen={showForm} onClose={() => setShowForm(false)} closeOnOverlayClick={false}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
          <ModalBody className="!text-navy-700">
            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
              <h1 className="mb-[20px] text-2xl font-bold">Edit Role</h1>
              <form onSubmit={handleSubmit}>
                <div className="mb-[20px]">
                  <label className="block text-sm font-medium text-navy-700 dark:text-white">
                    Role
                  </label>
                  <RoleDropdown
                    newUser={{ role: editedRole }}
                    handleInputChange={handleInputChange}
                    roleOptions={roleOptions}
                  />
                </div>
                <div className="flex justify-end gap-2">
                  <Button
                    onClick={() => setShowForm(false)}
                    isDisabled={isSubmitting}
                    className="btn btn-default"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitDisabled || isSubmitting}
                    isLoading={isSubmitting}
                    loadingText="Updating..."
                    className={`rounded-xl px-3 py-2.5 text-sm font-medium text-white transition duration-200 ${
                      isSubmitDisabled
                        ? "cursor-not-allowed bg-gray-400"
                        : "bg-green-500 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
                    }`}
                  >
                    Update
                  </Button>
                </div>
              </form>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditRoleModal;
