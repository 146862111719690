/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button } from '@chakra-ui/react';

const ReceiverAccountDetailsModal: React.FC<{
    detail: any;
    isOpen: boolean;
    onClose(): void;
}> = ({ isOpen, onClose, detail }) => {

    const [receiverAccountDetail, setReceiverAccountDetail] = useState<any>(detail);

    const closeModal = async () => {
        onClose();
        setReceiverAccountDetail(null);
    };

    const fieldsRendering = (fields: Record<string, any>) => {
        const renderInput = (fieldName: string, fieldValue: any) => {
            const capitalizeLabel = (label: string) => {
                let modifiedLabel = label
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');

                switch (label) {
                    case "bsb":
                        modifiedLabel = "BSB";
                        break;
                }

                return modifiedLabel;
            };

            return (
                <div className="mb-4" key={fieldName}>
                    <span className="mr-1 font-bold">{capitalizeLabel(fieldName)}:</span> {fieldValue}
                </div>
            );
        };

        return Object.entries(fields).map(([key, value]) => renderInput(key, value));
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                    <ModalBody className="!text-navy-700">
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
                            <h1 className="mb-[20px] text-2xl font-bold">Account Detail</h1>
                            <div className="mb-[20px] text-sm">
                                <div className="mb-4">
                                    <span className="mr-1 font-bold">Asset:</span> {receiverAccountDetail.asset}
                                </div>
                                <div className="mb-4">
                                    <span className="mr-1 font-bold">Method:</span> {receiverAccountDetail.method_name}
                                </div>
                                {fieldsRendering(receiverAccountDetail.method_details)}
                            </div>
                            <div className="flex gap-2 justify-end">
                                <Button
                                    onClick={() => closeModal()}
                                    className="btn btn-default"
                                >
                                    Close
                                </Button>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ReceiverAccountDetailsModal;