import React, { ReactElement } from "react";
import { Box } from "@mui/material";
import { Badge } from '@chakra-ui/react'
import { DataGrid, GridColDef, GridRenderCellParams, GridClasses } from "@mui/x-data-grid";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

type Debit = {
  data: string;
  description: string;
};

type Credit = {
  data: string;
  description: string;
};

type RowData = {
  id: string;
  created_at: string;
  subaccount: string;
  type: string;
  debit: Debit;
  credit: Credit;
  asset: string;
  status: string;
};

const customDataGridStyles: Partial<GridClasses> = {
  root: 'mui-table-custom',
  columnHeader: 'text-start text-md',
  row: ''
};

const PayoutList: React.FC<{
  isLoading: boolean;
  tableData: RowData[];
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
}> = ({
  isLoading,
  tableData,
  page,
  pageSize,
  rowCount,
  setPaginationModel
}) => {
    const userRole = localStorage.getItem("userRole")?.toLowerCase();

    interface RecordStatus {
      status: string;
      value: string;
      display: ReactElement;
    }

    const statusClasses = 'status';

    const statusList: RecordStatus[] = [
      { status: "pending", value: 'pending', display: <Badge className={`${statusClasses} status-pending`}>Pending</Badge> },
      { status: "complete", value: 'complete', display: <Badge className={`${statusClasses} status-successful`}>Completed</Badge> },
      { status: "cancelled", value: 'cancelled', display: <Badge className={`${statusClasses} status-cancelled`}>Cancelled</Badge> },
      { status: "failed", value: 'failed', display: <Badge className={`${statusClasses} status-failed`}>Failed</Badge> },
    ];

    const columns: GridColDef[] = [
      {
        field: "created_at",
        headerName: "Created Date",
        minWidth: 180,
        sortable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams<RowData, string>) =>
          formatDate(params.row.created_at),
      },
      {
        field: "subaccount", headerName: "Subaccount", minWidth: 180, sortable: false, flex: 1,
        renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
          params.row.subaccount
      },
      {
        field: "type", headerName: "Type", minWidth: 100, sortable: false, flex: 1,
        renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
          params.row.type
      },
      {
        field: "debit",
        headerName: "Debit",
        minWidth: 180,
        sortable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params: GridRenderCellParams<RowData, any, string>) => (
          params.row.debit.data
          // <div>
          //   <div>{params.row.debit.data ? params.row.debit.data : ""}</div>
          //   <div style={{ fontSize: '0.75rem', color: 'gray' }}>{params.row.debit.description ? params.row.debit.description : ""}</div>
          // </div>
        )
      },
      {
        field: "credit",
        headerName: "Credit",
        minWidth: 180,
        sortable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params: GridRenderCellParams<RowData, any, string>) => (
          <div>
            <div>{params.row.credit.data ? params.row.credit.data : ""}</div>
            <div style={{ fontSize: '0.85rem', color: 'gray' }}>{params.row.credit.description ? params.row.credit.description : ""}</div>
          </div>
        )
      },
      {
        field: "asset", headerName: "Asset", minWidth: 100, sortable: false, flex: 1,
        renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
          params.row.asset
      },
      {
        field: "status", headerName: "", minWidth: 200, sortable: false, flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams<RowData, any, string>) => {
          const status = params.value as string;
          const statusItem = statusList.find(item => item.status === status);
          return statusItem ? statusItem.display : null;
        }
      }
    ];

    const formatDate = (timestamp: string) => {
      return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY - hh:mm A");
    };

    const filterColumns = () => {
      let rebuildColumns = [];
      if (userRole !== "superadmin") {
        const excludeFields = ["subaccount"];
        rebuildColumns = columns.filter((column) => !!!excludeFields.includes(column?.field));
      } else {
        rebuildColumns = columns;
      }
      return rebuildColumns;
    };

    return (
      <>
        <div className="flex flex-wrap mt-6 pb-4 pl-3">
          <h3 className="text-lg text-navy-700 font-bold dark:text-white">
            Transactions 
          </h3>
        </div>
        <Box
          className="w-full card"
        >
          <DataGrid
            sx={{
              '& .MuiDataGrid-cell': {
                padding: '8px 16px',
              },
              '& .MuiDataGrid-columnHeader': {
                padding: '8px 16px',
              },
              "& .MuiTablePagination-input": {
                marginRight: "-20px"
              },
              "& .MuiTablePagination-displayedRows": {
                display: "none"
              },
            }}
            loading={isLoading}
            rows={tableData || []}
            columns={filterColumns()}
            getRowId={(row) => row.id}
            rowCount={rowCount}
            sortingMode="server"
            paginationMode="server"
            pageSizeOptions={[10, 25, 50, 100]}
            rowHeight={48}
            columnHeaderHeight={48}
            paginationModel={{ page: page, pageSize: pageSize }}
            onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            classes={customDataGridStyles}
            hideFooter={page === 0 && rowCount < pageSize}
            autoHeight
          />
        </Box>
      </>
    );
  };

export default PayoutList;
