/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ReactElement } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import InputField from "components/fields/InputField";
import SweetAlert2 from "react-sweetalert2";
import { Button } from '@chakra-ui/react';

const BatchDetailErrorModal: React.FC<{
    data: any;
    isOpen: boolean;
    onClose(): void;
}> = ({ data, isOpen, onClose }) => {

    const [errorInfo, setErrorInfo] = useState<any>(data);

    const closeModal = async () => {
        onClose();
        setErrorInfo(data);
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[850px] !max-w-[850px] md:top-[12vh]">
                    <ModalBody className="!text-navy-700">
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[850px] flex flex-col !z-[1004]">
                            <h1 className="mb-[20px] text-2xl font-bold">Error Info</h1>
                            <div className="mb-[20px]">
                                {errorInfo !== null ? errorInfo.filter((x: any) => !x.isValid).map((item: any, index: number) => (
                                    <div key={Math.random()}>
                                        {index > 0 && <div className="flex justify-center"><hr className="mb-3" style={{ width: '100%' }} /></div>}
                                        {(item.reference || item.reference === "") && (item.bsb || item.bsb === "") && (item.account_number || item.account_number === "") &&
                                            <div className="mb-1">
                                                <span className="mr-1 font-bold">CSV Line:</span> {item.rowIndex + 4}
                                            </div>
                                        }
                                        {(item.reference || item.reference === "") &&
                                            <div className="mb-1">
                                                <span className="mr-1 font-bold">Customer ID:</span> {item.reference === "" ? "-" : item.reference}
                                            </div>
                                        }
                                        {(item.bsb || item.bsb === "") &&
                                            <div className="mb-1">
                                                <span className="mr-1 font-bold">BSB:</span> {item.bsb === "" ? "-" : item.bsb}
                                            </div>
                                        }
                                        {(item.account_number || item.account_number === "") &&
                                            <div className="mb-1">
                                                <span className="mr-1 font-bold">Account No.:</span> {item.account_number === "" ? "-" : item.account_number}
                                            </div>
                                        }
                                        {(item.reference || item.reference === "") && (item.bsb || item.bsb === "") && (item.account_number || item.account_number === "") ? (
                                            <div className="mb-3">
                                                <span className="mr-1 font-bold">Reason:</span>
                                                <br />
                                                <ul className="ml-8" style={{ listStyle: 'circle' }}>
                                                    {Object.keys(item.errors).map((key) => (
                                                        <li key={Math.random()}>{key}: {item.errors[key].message}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : (

                                            <div className="mb-3">
                                                {item.rowIndex &&
                                                    <>
                                                        <span className="mr-1 font-bold">Footer:</span>
                                                        <br />
                                                    </>
                                                }
                                                <ul className="ml-8" style={{ listStyle: 'circle' }}>
                                                    {Object.keys(item.errors).map((key) => (
                                                        <li key={Math.random()}>{key}: {item.errors[key].message}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                )) : (null)}
                            </div>
                            <div className="flex gap-2 justify-end">
                                <Button
                                    onClick={() => closeModal()}
                                    className="btn btn-default"
                                >
                                    Close
                                </Button>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default BatchDetailErrorModal;
