/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useState } from "react";
import { Box } from "@mui/material";
import { Badge } from '@chakra-ui/react'
import { DataGrid, GridColDef, GridRenderCellParams, GridClasses } from "@mui/x-data-grid";
import { AiOutlineLink } from "react-icons/ai";
import { Tooltip, IconButton } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { MdOutlineCancel, MdOutlineContentCopy } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";
import PayoutCancelConfirmModal from "../../shared/PayoutCancelConfirmModal";
import ReceiverAccountDetailsModal from "../../shared/ReceiverAccountDetailsModal";

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

type Merchant = {
  id: string;
  name: string;
};

type Subaccount = {
  id: string;
  name: string;
};

type Receiver = {
  id: string;
  firs_tname: string;
  last_name: string;
  email: string;
};

type ReceiverAccount = {
  id: string;
  email: string;
  method_details: Object;
};

type PayoutProvider = {
  id: string;
  name: string;
};

type RowData = {
  id: string;
  status: string;
  amount: string;
  asset: string;
  created_at: string;
  note: string;
  merchant: Merchant;
  subaccount: Subaccount;
  transactions: any[];
  receiver: Receiver;
  receiver_account: ReceiverAccount;
  payout_provider: PayoutProvider;
  batch_id: string;
  cause: any;
  reference_tracking: any;
  method_name: string;
};

const customDataGridStyles: Partial<GridClasses> = {
  root: 'mui-table-custom',
  columnHeader: 'text-start font-bold text-lg',
  row: '',
};

const PayoutList: React.FC<{
  isLoading: boolean;
  tableData: RowData[];
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
  currentLastKey: string;
  triggerReloadPayoutList(): void;
}> = ({
  isLoading,
  tableData,
  page,
  pageSize,
  rowCount,
  setPaginationModel,
  currentLastKey,
  triggerReloadPayoutList
}) => {

    const [payoutToCancel, setPayoutToCancel] = useState<any>(null);
    const [isOpenPayoutCancelConfirmModal, setIsOpenPayoutCancelConfirmModal] = useState<boolean>(false);

    const [isOpenReceiverAccountDetailModal, setIsOpenReceiverAccountDetailModal] = useState<boolean>(false);
    const [receiverAccountDetailToDisplay, setReceiverAccountDetailToDisplay] = useState<any>(null);

    const userRole = localStorage.getItem("userRole")?.toLowerCase();

    interface PayoutStatusItems {
      status: string;
      value: string;
      display: ReactElement;
    }

    const statusClasses = 'status';

    const statusList: PayoutStatusItems[] = [
      { status: "awaiting", value: 'awaiting', display: <Badge className={`${statusClasses} status-awaiting`}>Awaiting</Badge> },
      { status: "sent_mail", value: 'sent_mail', display: <Badge className={`${statusClasses} status-sent_mail`}>Email Sent</Badge> },
      { status: "pending", value: 'pending', display: <Badge className={`${statusClasses} status-pending`}>Pending</Badge> },
      { status: "unassessed", value: 'unassessed', display: <Badge className={`${statusClasses} status-unassessed`}>Unassessed</Badge> },
      { status: "in_progress", value: 'in_progress', display: <Badge className={`${statusClasses} status-in_progress`}>In Progress</Badge> },
      { status: "successful", value: 'successful', display: <Badge className={`${statusClasses} status-successful`}>Completed</Badge> },
      { status: "cancelled", value: 'cancelled', display: <Badge className={`${statusClasses} status-cancelled`}>Cancelled</Badge> },
      { status: "failed", value: 'failed', display: <Badge className={`${statusClasses} status-failed`}>Failed</Badge> },
      { status: "successful_pending_kyc", value: 'successful_pending_kyc', display: <Badge className={`${statusClasses} status-successful_pending_kyc`}>Successful Pending KYC</Badge> }
    ];

    const columns: GridColDef[] = [
      {
        field: "id",
        headerName: "",
        width: 40,
        sortable: false,
        renderCell: (params: GridRenderCellParams<RowData, string>) =>
          <Tooltip title="Copy Payout ID" arrow placement="top">
            <div className="">
              <IconButton
                id={"button-copy-payoutid-" + params?.row?.id}
                onClick={() => handleClickCopy(params.row.id)}
              >
                <MdOutlineContentCopy className="h-5 w-5 text-navy-800" />
              </IconButton>
            </div>
          </Tooltip>
      },
      {
        field: "created_at",
        headerName: "Created Date",
        sortable: false,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<RowData, string>) =>
          formatDate(params.row.created_at),
      },
      {
        field: "subaccount",
        headerName: "Subaccount",
        sortable: false,
        minWidth: 160,
        renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
          params.row.subaccount.name,
      },
      {
        field: "batch_id",
        headerName: "Batch",
        sortable: false,
        headerAlign: 'center',
        minWidth: 70,
        align: 'center',
        renderCell: (params: GridRenderCellParams<RowData, any, string>) => {
          if (params.row.batch_id && params.row.batch_id !== "") {
            return (
              <Tooltip title="View Batch Detail" arrow placement="right">
                <div>
                  <IconButton
                    id={"button-view-batch-" + params?.row?.batch_id}
                    onClick={() => handleClickViewBatch(params.row.batch_id)}
                  >
                    <AiOutlineLink className="flex justify-center h-5 w-5" />
                  </IconButton>
                </div>
              </Tooltip>
            );
          } else {
            return null;
          }
        }
      },
      {
        field: "amount", headerName: "Amount",
        minWidth: 80,
        sortable: false, headerAlign: 'right', align: 'right',
        renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
          displayAmount(params.row.amount)
      },
      {
        field: "asset", headerName: "Asset",
        minWidth: 60, sortable: false, headerAlign: 'center', align: 'center',
      },
      {
        field: "receiver",
        headerName: "Email",
        sortable: false,
        minWidth: 250,
        renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
          params.row.receiver.email,
      },
      {
        field: "account_detail",
        headerName: "Account",
        sortable: false,
        minWidth: 80,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          return (
            <>
              <Tooltip title="Account Detail" arrow placement="right">
                <IconButton
                  id={"button-details-" + params?.row?.id}
                  onClick={() => handleAccountClickDetails(params.row.id)}
                >
                  <AiOutlineInfoCircle className="h-5 w-5 text-navy-700" />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
      { field: "note", headerName: "Note", sortable: false },
      { field: "reference_tracking", headerName: "Ref. Tracking", minWidth: 170, sortable: false },
      {
        field: "status", headerName: "", sortable: false, minWidth: 140,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams<RowData, any, string>) => {
          const status = params.value as string;
          const statusItem = statusList.find(item => item.status === status);      
          return (
            <>
              {userRole === 'superadmin' && status === 'failed' && params.row.cause && params.row.cause.fail ? (
                <Tooltip title={params.row.cause.fail} arrow placement="top">
                  {statusItem ? statusItem.display : null}
                </Tooltip>
              ) : (
                <>
                  {statusItem ? statusItem.display : null}
                </>
              )}
            </>
          );
        }
      },
      {
        field: "actions", headerName: "", sortable: false, flex: 1,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => {
          return (
            <>
              {(params?.row?.status === 'awaiting' || params?.row?.status === 'sent_mail' || params?.row?.status === 'pending') &&
                (
                  (userRole === 'superadmin' && ['open', 'close', 'queue'].includes(params?.row?.batch_status))
                  ||
                  ((userRole === 'merchant' || userRole === 'admin') && params?.row?.file_bucket === null && params?.row?.file_key === null && params?.row?.batch_status === 'open')
                ) && (
                  <Tooltip title="Cancel" arrow placement="right">
                    <div className="">
                      <IconButton
                        id={"button-detail-" + params?.row?.id}
                        onClick={() => handleClickCancel(params.row.id)}
                      >
                        <MdOutlineCancel className="h-5 w-5 text-red-500" />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}
            </>
          );
        },
      }
    ];

    const handleClickCancel = (id: any) => {
      const payout = tableData.filter((x: any) => x.id === id)[0];
      setPayoutToCancel(payout);
      showPayoutCancelConfirmModal();
    };

    const showPayoutCancelConfirmModal = () => {
      setIsOpenPayoutCancelConfirmModal(true);
    }

    const closePayoutCancelConfirmModal = () => {
      setPayoutToCancel(null);
      setIsOpenPayoutCancelConfirmModal(false);
    }

    const handleAccountClickDetails = (id: any) => {
      const payoutRecord = tableData.filter((x: any) => x.id === id)[0];
      const accountDetail = {
        asset: payoutRecord.asset,
        method_name: payoutRecord.method_name,
        method_details: payoutRecord.receiver_account?.method_details
      }
      setReceiverAccountDetailToDisplay(accountDetail);
      showAccountDetailsModal();
    };
  
    const showAccountDetailsModal = () => {
      setIsOpenReceiverAccountDetailModal(true);
    }
  
    const closeAccountDetailsModal = () => {
      setReceiverAccountDetailToDisplay(null);
      setIsOpenReceiverAccountDetailModal(false);
    }

    const handleClickCopy = async (payoutId: string) => {
      try {
        await navigator.clipboard.writeText(payoutId);
      } catch (err) {
        console.error('Failed to copy payout ID: ', err);
      }
    };

    const formatDate = (timestamp: string) => {
      return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY - hh:mm A");
    };

    const handleClickViewBatch = (batchid: string) => {
      const url = `/${userRole}/batch/${batchid}`;
      window.open(url, '_blank');
    };

    const filterColumns = () => {
      let rebuildColumns = [];
      if (userRole !== "superadmin") {
        const excludeFields = ["subaccount"];
        rebuildColumns = columns.filter((column) => !!!excludeFields.includes(column?.field));
      } else {
        rebuildColumns = columns;
      }
      return rebuildColumns;
    };

    const displayAmount = (amount: any) => {
      if (amount === 0) {
        return "$0.00";
      }
      return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
      <>
        {(userRole === 'superadmin' || userRole === 'admin' || userRole === 'merchant') && payoutToCancel &&
          <PayoutCancelConfirmModal triggerReload={triggerReloadPayoutList} isOpen={isOpenPayoutCancelConfirmModal} payoutToCancel={payoutToCancel} onClose={() => closePayoutCancelConfirmModal()} />
        }
        {(userRole === 'superadmin' || userRole === 'admin' || userRole === 'merchant') && receiverAccountDetailToDisplay &&
          <ReceiverAccountDetailsModal detail={receiverAccountDetailToDisplay} isOpen={isOpenReceiverAccountDetailModal} onClose={() => closeAccountDetailsModal()} />
        }
        <div className="flex flex-wrap mt-6 pb-4 pl-3">
          <h3 className="text-lg text-navy-700 font-bold dark:text-white">
            Payouts
          </h3>
        </div>
        <Box
          className="w-full card"
        >
          <DataGrid
            sx={{
              '& .MuiDataGrid-cell': {
                padding: '8px 16px',
              },
              '& .MuiDataGrid-columnHeader': {
                padding: '8px 16px',
              },
              "& .MuiTablePagination-input": {
                marginRight: "-20px"
              },
              "& .MuiTablePagination-displayedRows": {
                display: "none"
              },
            }}
            loading={isLoading}
            rows={tableData || []}
            columns={filterColumns()}
            getRowId={(row) => row.id}
            rowCount={rowCount}
            sortingMode="server"
            paginationMode="server"
            pageSizeOptions={[10, 25, 50, 100]}
            rowHeight={48}
            columnHeaderHeight={48}
            paginationModel={{ page: page, pageSize: pageSize }}
            onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            classes={customDataGridStyles}
            hideFooter={currentLastKey === "" && page === 0}
            autoHeight
          />
        </Box>
      </>
    );
  };

export default PayoutList;
