/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ReactElement } from "react";
import axios from "axios";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { Select, Button, Badge, Divider, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { FaAngleLeft } from "react-icons/fa";
import moment from "moment";
import SweetAlert2 from "react-sweetalert2";

import AccountBalancesTab from "./components/AccountBalancesTab";
import AccountLedgerAdjustmentTab from "./components/AccountLedgerAdjustmentTab";
import { useParams, useNavigate } from "react-router-dom";

const AccountDetail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [fetchingBalance, setIsFetchingBalance] = useState(false);
  const [accountDetailForEdit, setAccountDetailForEdit] = useState(null);
  const [balancesData, setBalancesData] = useState(null);
  const [currentTab, setCurrentTab] = useState<string>('balances');
  const [countries, setCountries] = useState<any>(null);
  const [dialcodeList, setDialcodeList] = useState<any>(null);
  const [swalProps, setSwalProps] = useState<any>({});
  const navigate = useNavigate();
  const { subaccount_id } = useParams();
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  useEffect(() => {
    const fetchData = async () => {
      await fetchAccountDetail(subaccount_id);
    };

    fetchData();
  }, []);

  const fetchAccountDetail = async (accountId: string, onlyBalance: boolean = false) => {
    try {

      if(onlyBalance) {
        setIsFetchingBalance(true);
      } else {
        setAccountDetailForEdit(null);
        setIsLoading(true);
      }

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/subaccount/get-subaccount-by-id/${accountId}?with_balance=true`
      );

      let accountData = response.data.subaccounts?.payload;
      let balancesData = response.data.balances

      setAccountDetailForEdit(accountData);
      setBalancesData(balancesData);
      setIsLoading(false);
      setIsFetchingBalance(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data", error);
    }
  };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);

  interface CustomerStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }

  const statusClasses = 'status';

  const statusList: CustomerStatusItems[] = [
    { status: "pending", value: 'pending', display: <Badge className={`${statusClasses} status-pending`}>Pending</Badge> },
    { status: "active", value: 'active', display: <Badge className={`${statusClasses} status-active`}>Active</Badge> },
    { status: "complete", value: 'complete', display: <Badge className={`${statusClasses} status-successful`}>Completed</Badge> },
  ];

  const statusDisplay = (status: string) => {
    const statusItem = statusList.find(item => item.status === status);
    return statusItem ? statusItem.display : null;
  };

  const fetchCountries = async () => {
    try {

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/country/get-all-countries`
      );

      let countries = response?.data;
      let dialcodeList: any = [];
      if (countries.length > 0) {
        countries.forEach((c: any) => {
          if (c.dialcode !== null) {
            if (c.dialcode in dialcodeList) {
              dialcodeList[c.dialcode] = dialcodeList[c.dialcode] + ', ' + c.country_name;
            } else {
              dialcodeList[c.dialcode] = '(+' + c.dialcode + ') ' + c.country_name;
            }
          }
        });
      }

      const sortedDialcodeList: any = [];
      Object.keys(dialcodeList).sort().forEach((key: any) => {
        sortedDialcodeList[key] = dialcodeList[key];
      });

      setCountries(countries);
      setDialcodeList(sortedDialcodeList);
    } catch (error) {
      console.error("Error fetching countries", error);
    }
  };

  const handleClickBack = () => {
    navigate(`/${userRole}/account-management`);
  }

  return (
    <>
      <Card className="mt-6 card  card-pad" >
        {!isLoading && accountDetailForEdit !== null ? (
          <>
            <div className="flex items-center">
              <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                {!isLoading && accountDetailForEdit !== null ? accountDetailForEdit.suba_name : (<></>)}
              </h4>
              <div className="ml-auto flex items-center">
                <Button
                  disabled={isProcessing}
                  onClick={() => handleClickBack()}
                  className="btn btn-default"
                >
                  <FaAngleLeft />&nbsp;Back to Accounts
                </Button>
              </div>
            </div>
            {/* <hr className="mb-3" />
            <div className="flex flex-wrap">
              <div className="mb-3 text-sm font-bold text-navy-700 dark:text-white">
                <div><span className="mr-1">Account Name:</span>{!isLoading && accountDetailForEdit !== null ? accountDetailForEdit.suba_name : (<></>)}</div>
              </div>
            </div> */}
          </>
        ) : (
          <>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3 mt-3" style={{ animationDuration: '0.6s' }}></div>
          </>
        )}
      </Card>

      <Tabs className="mt-6 card">
        <TabList className="flex rounded-tl-custom rounded-tr-custom">
          <Tab disabled={!isLoading && accountDetailForEdit === null} onClick={() => setCurrentTab('balances')} className={"px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom " + (currentTab === 'balances' ? "border-b-4 border-green-500" : "")}>
            <strong>Balances</strong>
          </Tab>
          <Tab disabled={!isLoading && accountDetailForEdit === null} onClick={() => setCurrentTab('ledger')} className={"px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom " + (currentTab === 'ledger' ? "border-b-4 border-green-500" : "")}>
            <strong>Ledger Adjustment</strong>
          </Tab>
        </TabList>
        <TabPanels className="border-t border-gray-200 bg-white rounded-bl-custom rounded-br-custom">
          <TabPanel>
            <AccountBalancesTab data={balancesData} fetchingBalance={fetchingBalance} />
          </TabPanel>
          <TabPanel className="pr-10 pl-10 pt-5 pb-5 rounded-bl-custom rounded-br-custom">
            <AccountLedgerAdjustmentTab detail={accountDetailForEdit} setIsProcessing={setIsProcessing} isProcessing={isProcessing} setSwalProps={setSwalProps} fetchAccountDetail={fetchAccountDetail} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>
      </SweetAlert2>
    </>
  );
};

export default AccountDetail;
